import React from "react"
import PageTemplate from "../templates/pageTemplate"

import Markdown from "markdown-to-jsx"

import data from "../../content/pages/terms.yml"

const TermsPage = () => {
  const { content, title } = data

  return (
    <PageTemplate title={title}>
      <Markdown>{content}</Markdown>
    </PageTemplate>
  )
}

export default TermsPage
